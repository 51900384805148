.edit-json-template {
  textarea {
    min-height: 600px;
  }
}

.error {
    border-color: red;
}

.error:focus {
    border-color: red;
    border-size: 2px;
}

.processtree .input-group .pagefilter {
    display: inline-block;
    margin: 0;
    font-size: 0.6rem;
    width: 15rem;
    padding: 0;
    padding-left: 10px;
    line-height: 1;
    height: 1.25rem; //???
}

.defaulttheme {
    .edit-widget-template {
        .widget-option-overview > .row {
            border-top: 1px solid #DDD;
            padding-top: 5px;
            div.columns:nth-child(2) {
                padding-left: 25px;
            }

            // Spalte 1
            .large-2 {
                padding: 0;
                a {
                    border: 1px solid $defaultPrimaryColor;
                    display: inline-block;
                    text-align: center;
                    line-height: 1;
                    -webkit-appearance: none;
                    vertical-align: middle;
                    border-radius: 4px;
                    padding: 0.85em 1em;
                    margin: 0 0 1rem 0;
                    background-color: transparent;
                    font-size: .6rem;
                    width: 100%;
                }
            }
        }
    }

    .widget-code-mirror {
        margin-bottom: 1rem;

        &.expanded {
            position: relative;
            height: 670px;

            .CodeMirror {
                width: 120%;
            }
        }
    }
}

//  _   _ _______    _____ _    _ _____  ______ _____    _______ _    _ ______ __  __ ______
// | \ | |__   __|  / ____| |  | |  __ \|  ____|  __ \  |__   __| |  | |  ____|  \/  |  ____|
// |  \| |  | |    | (___ | |  | | |__) | |__  | |__) |    | |  | |__| | |__  | \  / | |__
// | . ` |  | |     \___ \| |  | |  ___/|  __| |  _  /     | |  |  __  |  __| | |\/| |  __|
// | |\  |  | |     ____) | |__| | |    | |____| | \ \     | |  | |  | | |____| |  | | |____
// |_| \_|  |_|    |_____/ \____/|_|    |______|_|  \_\    |_|  |_|  |_|______|_|  |_|______|
//
//
.ntsupertheme {

    legend {
        font-size: 1.25rem;
        line-height: 2rem;
        color: $highlightColor;
    }

    .button {
        color: #606060;
        background-color: $lightGray;

        &:hover {
            color: lighten(#606060, 10%);
            background-color: darken($lightGray, 5%);
        }
    }

    .sortDownButton,
    .sortUpButton {
        border: none;
        background-size: 21px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        width: 21px;
        height: 21px;

        &:disabled,
        &[disabled] {
            opacity: .25;
        }
    }
    .sortDownButton {
        background-image: url(img/icon_sort_down.png);

        &:hover:not(:disabled) {
            background-image: url(img/icon_sort_down_hover.png);
        }
    }
    .sortUpButton {
        background-image: url(img/icon_sort_up.png);

        &:hover:not(:disabled) {
            background-image: url(img/icon_sort_up_hover.png);
        }
    }

    .edit-widget-template {

        // Übersicht
        .widget-option-overview {
            margin-top: calc(-1rem - 10px);

            > .row {
                &:not(:first-child) {
                    border-top: 1px solid $simpleLine;
                }

                > .columns {
                    padding-top: 12px;
                    padding-bottom: 12px;
                }

                // Spalte 1
                > .large-2 {
                    a {
                        font-weight: bold;
                    }
                }

                // Spalte 2
                > .large-10 {
                    min-height: 43px;
                    background-color: #F6F6F6;
                    padding-left: 15px;
                }
            }
        }

        // Alle anderen Eigenschaften/Tabs
        .tabs-panel > div > .row {

            // Spalte 1
            > .small-2 {
                label {
                    font-weight: bold;
                    line-height: 2.4375rem;
                }
            }
        }

        fieldset {
            margin-bottom: 1rem;
        }

        .customroundbutton {
            margin-bottom: 0.5rem;
        }
    }

    // Codemirror-Editor
    $cmFontColor: #B1B1B1;
    .widget-code-mirror {

        // Vergrößert
        &.expanded {
            position: relative;
            height: calc(50vh + 37px); // 37px ist die Höhe der Action-Line

            .expandable-wrapper {
                position: absolute;
                width: 130%;
                left: -30%;
                box-shadow: 3px 3px 6px #666666;

                @media (min-width: 1920px) {
                    width: 120%;
                    left: -20%;
                }

                @media (min-width: 3000px) {
                    width: 115%;
                    left: -15%;
                }

                .CodeMirror {
                    position: relative;
                    margin-left: 0;
                    box-shadow: none;
                    min-height: 50vh;
                }
            }
        }

        .CodeMirror {
            border: 1px solid $codemirrorBorderColor;
            border-radius: 4px 4px 0 0;
        }
        .CodeMirror-gutters {
            background-color: $codemirrorBgColor;
        }
        .CodeMirror-linenumber {
            color: #A9A9A9;
        }
        .codemirror-action-bar {
            display: flex;
            justify-content: flex-end;
            color: $cmFontColor;
            background-color: $codemirrorBgColor;
            border: 1px solid $codemirrorBorderColor;
            border-top: 0;
            border-radius: 0 0 4px 4px;

            > * {
                display: flex;
                align-items: center;
                margin-right: 3rem;

                label {
                    margin-right: 1rem;
                    color: $cmFontColor;
                    font-weight: 600;
                }

                button {
                    &.active,
                    &:hover {
                        color: #FFF;
                        background-color: #D2D2D2;
                    }
                }

                &.font-size button {
                    width: 2rem;
                    border-radius: 14.25px;
                    padding: 4px 0;
                    font-weight: 600;

                    &.small {
                        font-size: 0.75rem;
                    }
                    &.medium {
                        font-size: .875rem;
                    }
                    &.large {
                        font-size: 1rem;
                    }
                }

                &.height,
                &.expand {
                    button {
                        padding: 10px 6px;
                        border-radius: 0;
                    }
                }

                &.height button {
                    width: 5rem;
                }
            }
        }

        // Font Size
        &.fs-small .CodeMirror {
            font-size: 0.8rem;
            line-height: 0.8rem;
        }
        &.fs-medium .CodeMirror {
            font-size: 1rem;
            line-height: 1rem;
        }
        &.fs-large .CodeMirror {
            font-size: 1.2rem;
            line-height: 1.2rem;
        }

        // Höhe
        &.height-small .CodeMirror {
            height: 300px;
        }
        &.height-medium .CodeMirror {
            height: 600px;
        }
        &.height-large .CodeMirror {
            height: 900px;
        }
    }
}