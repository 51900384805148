@import '../../app';
@import 'foundation';
@import 'motion-ui';
@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;
@include motion-ui-transitions;
@include motion-ui-animations;
// Custom variables and imports
$primary-font-color: #333;
// defaulttheme
$defaultPrimaryColor: #3F3F3F;
// ntsupertheme
$highlightColor: #00A8FF;
$simpleLine: #E7E7E7;
$formBG: #E7E7E7;
$codemirrorBgColor: #F4F4F4;
$codemirrorBorderColor: #C7C7C7;
$lightGray: #E5E5E5;
@import '../base';
@import 'custom';
@mixin roundBorders() {
    border-radius: 4px;
}

@mixin boxShadow() {
    box-shadow: 3px 3px 3px #ccc;
}

@mixin simpleTopBorder() {
    border-top: 1px solid #E7E7E7
}

body {
    color: $primary-font-color;
}

H2 {
    font-weight: 600;
    font-size: 34px;
    color: #252525;
    text-transform: uppercase;
}

.grid-frame,
.grid-block,
.grid-block>.ng-scope {
    height: 100%;
}


/*Tabs für alle Forms */

.tabs {
    border: none;
}

.tabs-content {
    border: none;
}


/* Styles fuer das Backend */

.justifycenter {
    justify-content: center;
}

.backend-template {
    /* css-Code unterhalb von foundation */
    .row {
        max-width: 100%;
    }
    .row90 {
        max-width: 90% !important;
        min-width: 90% !important;
    }
    .process-content {
        display: flex;
        .tree {
            margin-right: -1px;
            border-right: 1px solid $simpleLine;
        }
        .properties {
            border-left: 1px solid $simpleLine;
            padding-left: 20px;
            flex: 1;
            margin-top: 30px;
        }
    }
    .processtreeContainer {
        overflow: auto;
        max-height: 600px;
        border: 1px solid $simpleLine;
        border-right: none;
        padding: 2px;
        box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        margin: 0.5rem 0;
        &:after {
            display: block;
            content: "";
            height: 20px;
        }
    }
    .layoutEditor {
        position: relative;
        width: 90%;
        border: 1px dashed #ccc;
        background-size: 100%;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin: auto;
        margin-bottom: 0.5rem;
        .pageContent {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            >* {
                transform: scale(0.5);
                transform-origin: top center;
            }
        }
        .columns.gridCell {
            border: 1px dashed #ccc;
            min-height: 3rem;
            padding-left: 0.31rem;
            padding-right: 0.31rem;
            position: relative;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: auto 90%;
            &:hover:not(.filled) {
                background-color: #eee;
                cursor: pointer;
                &:before {
                    color: #aaa;
                    content: '+';
                    font-size: 2rem;
                    position: absolute;
                    top: 0;
                    left: 0;
                    text-align: center;
                    width: 100%;
                    height: 100%;
                }
            }
            &.filled {
                background-color: #ccddff;
            }
            &.selected {
                border: 2px solid #c00;
            }
            &.selecting {
                background-color: #ddffdd;
            }
            // Es folgen die widgetspez. Aussehen:
            &.formDatePicker {
                background-image: url('img/icon_kalender.svg');
            }
            &.dynamicTextInline {
                background-image: url('img/icon_text_inline.svg');
            }
            &.dynamicTextBlock {
                background-image: url('img/icon_text_block.svg');
            }
            &.boxContainer {
                background-image: url('img/icon_box_container.svg');
            }
        }
        .row {
            margin-left: 0;
            margin-right: 0;
        }
        +* {
            clear: both;
        }
    }
    .expander {
        display: inline-block;
        cursor: s-resize;
        width: 16px;
        height: 16px;
        &.plus,
        &.minus {
            text-align: center;
            background-color: $primary-color;
            color: white;
            font-weight: bold;
            border-radius: 50%;
            line-height: 16px;
            margin-top: 3px;
        }
    }
    .expander.minus {
        background-color: #99baee;
    }
    div.content-first {
        margin-top: 35px;
    }
    input.invisible {
        display: none;
    }
    .processtree {
        min-width: 250px;
        margin-left: 0px;
    }
    ul.processtree {
        ul {
            margin: 0 0.5rem;
        }
        li {}
        li.selected {
            border: 1px solid #ccc;
            border-radius: 4px;
            padding: 0.3rem;
            margin-left: -0.3rem;
            background-color: #fdfdfd;
            margin-top: 0.3rem;
            margin-bottom: 0.3rem;
            .button.hollow:not(.selected) {
                background-color: $white;
            }
            button.selected {
                background-color: $primary-color;
                color: $white;
            }
        }
    }
    .recursive-widget {
        button.selected {
            background-color: $primary-color;
            color: $white;
        }
    }
    ;
    ul.list-unstyled {
        li {
            list-style-type: none;
        }
    }
    /* Tabellenzellen und -header */
    td,
    th {
        .button {
            margin: 0;
            @include roundBorders();
        }
    }
    a.button,
    button.button {
        @include roundBorders();
    }
    /* Allgemeine Klassen */
    .nomargin {
        margin: 0;
    }
    /* buttons */
    button.xsmall {
        padding: 0.25rem;
        margin: 0;
        max-width: 244px;
        text-align: left;
    }
    .backend-grid {
        .headline {
            font-weight: bold;
            margin: 4px 2px 10px 2px !important; //grrrr
            border-bottom: 1px solid $simpleLine;
        }
    }
    div.error {
        padding: 20px;
        margin: 20px;
        border: 2px solid red;
        font-weight: bold;
    }
    .edit-process-template {
        .tabs-title {
            a {
                padding: 1.25rem;
            }
        }
        .backendAclForm {
            .acl-grid-headline {
                .user,
                .right {
                    font-weight: bold;
                    color: $header-color;
                    padding: 1rem 0.25rem;
                }
            }
            .right {
                border-left: 1px solid $light-gray;
                text-align: center;
            }
            .acl-remove-all-container,
            .acl-enable-container {
                font-size: small;
            }
        }
    }
}


/* Stakeholder */

.stakeholder {
    /* Stakeholder Blau*/
    $stakeholder-blue: #00A0F5;
    $stakeholder-grau: #78879B;
    $stakeholder-hellgrau: #D9DEE8;
    $stakeholder-background: #ffffff;
    .head,
    .head1 {
        color: $stakeholder-blue;
    }
    .description,
    .head2 {
        color: $stakeholder-grau;
    }
    table thead,
    table thead tr {
        border: none;
    }
    table thead tr th {
        background-color: white;
        padding-left: 3px;
        padding-right: 3px;
        padding-top: 3px;
        padding-bottom: 0px;
        border: none;
        // border-left: 10px solid $stakeholder-background;
        // border-right: 10px solid $stakeholder-background;
        // border-bottom: 3px solid $stakeholder-hellgrau;
    }
    table thead tr th div {
        padding: 3px;
        padding-left: 6px;
        background-color: $stakeholder-hellgrau;
        // border-left: 10px solid $stakeholder-background;
        // border-right: 10px solid $stakeholder-background;
        // border-bottom: 3px solid $stakeholder-hellgrau;
    }
    table thead tr th:last-child {
        visibility: hidden;
    }
    table tbody {
        border: 3px solid $stakeholder-hellgrau;
        padding: 3px;
    }
    table tbody tr {
        background-color: white;
        border-bottom: 1px solid $stakeholder-hellgrau;
    }
    /* Rahmen oben */
    table tbody tr:first-child td {
        border-top: 3px solid $stakeholder-hellgrau;
    }
    .button {
        color: black;
        background-color: white;
        border: 1px solid $stakeholder-blue;
    }
    .button:hover {
        color: white;
        background-color: $stakeholder-blue;
        border: 1px solid $stakeholder-blue;
    }
    .label.outlined {
        background-color: white;
        border: 1px solid $stakeholder-blue;
    }
}

.editable-widget-option-label {
    .unchanged {
        color: green;
    }
    .replace {
        color: orange;
    }
    .remove {
        color: red;
    }
    .add {
        color: orange;
    }
}

.grid-selection {
    flex-wrap: wrap;
    button.current {
        border-color: green;
        color: green;
        &:active,
        &:hover,
        &:focus {
            border-color: green;
            color: green;
        }
    }
    button.expand {
        border-color: map-get($foundation-palette, primary);
        color: map-get($foundation-palette, primary);
        &:active,
        &:hover,
        &:focus {
            border-color: map-get($foundation-palette, primary);
            color: map-get($foundation-palette, primary);
        }
    }
    button.reduce {
        border-color: red;
        color: red;
        &:active,
        &:hover,
        &:focus {
            border-color: red;
            color: red;
        }
    }
}

.component-properties {
    display: flex;
    .tree {
        margin-right: 1px dotted black;
        padding-right: 10px;
    }
    .properties {
        flex: 1;
        .tabs {
            border: 0;
        }
    }
}

.codemirror-bottommargin {
    .CodeMirror-wrap {
        margin-bottom: 1rem;
    }
}

.system-error {
    font-family: monospace;
    white-space: pre-line;
}

.simple-ui-directive-button {
    margin: 5px;
    padding: 5px;
    border: 1px solid yellow;
    border-color: map-get($foundation-palette, primary);
    color: black;
    &:active,
    &:hover,
    &:focus {
        border-color: map-get($foundation-palette, primary);
        color: black;
        background-color: $light-gray;
    }
}

.clickable-text {
    cursor: pointer;
}

.underlined {
    text-decoration: underline;
}

.inline {
    display: inline;
}

.row90 {
    max-width: 90%;
}

.align-right {
    text-align: right;
}

.pull-right {
    float: right;
}

.simpleui-col-width {
    position: relative;
    .columndefrow {
        position: relative;
        border-left: 2px solid map-get($foundation-palette, primary);
        border-right: 2px solid map-get($foundation-palette, primary);
        .columndef {
            border-top: 2px solid map-get($foundation-palette, primary);
            border-bottom: 2px solid map-get($foundation-palette, primary);
            text-align: center;
            min-height: 80px;
        }
        .dragger {
            position: absolute;
            cursor: col-resize;
            height: 100%;
            background-color: map-get($foundation-palette, primary);
            z-index: 10;
            &.ghost {
                z-index: 1;
                opacity: .25;
            }
        }
        .gridindicator {
            position: absolute;
            height: 100%;
            background-color: map-get($foundation-palette, primary);
            z-index: 0;
            opacity: .15;
        }
    }
}

.lang-service-editor {
    div.editvalue {
        display: flex;
        .input {
            flex: 1 1 auto;
        }
        .evbutton {
            flex: 0 0 auto;
        }
    }
    span.value {
        cursor: pointer;
    }
    table {
        // fixed => px breiten vor % breiten!
        table-layout: fixed;
        tr.headline {
            border-bottom: 1px solid #999;
            th {
                cursor: pointer;
            }
            &.filter {
                input {
                    margin: 0;
                }
            }
            .sort-indicator {
                background-image: url("img/ui-icons_222222_256x240.png");
                background-repeat: no-repeat;
                background-position: -160px 0px;
                display: inline-block;
                width: 20px;
                height: 20px;
                &.asc {
                    background-position: -65px -15px;
                }
                &.desc {
                    background-position: 0px -15px;
                }
            }
        }
        .col-key {
            width: 30%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrwap;
            &.with-comment {
                cursor: pointer;
                &::before {
                    content: "i";
                    border: 1px solid #444;
                    border-radius: 50%;
                    display: inline-block;
                    width: 1.2em;
                    text-align: center;
                    margin-right: 5px;
                    font-size: 75%;
                }
            }
        }
        .col-val {
            width: 70%;
        }
        .col-modified {
            width: 130px;
            text-align: center;
        }
    }
}

.fh-columns,
.fh-rows,
.fh-cell-properties,
.editable-subnav-editor {
    fieldset {
        border: 1px solid #ccc;
        &.fieldset {
            border-width: 0;
            margin: 0;
            padding: 0.25rem 1.25rem;
            &.bordered {
                border-width: 1px;
                margin-bottom: 1.25rem;
            }
            >div {
                padding-top: 20px;
            }
        }
        legend {
            cursor: pointer;
            button.remove {
                postion: absolute;
                right: 10px;
                background-color: #fff;
            }
        }
    }
    button.addRow,
    button.addColumn,
    button.addCellProperties {
        float: right;
        position: relative;
        top: -25px;
    }
}

table tbody td>input,
table tbody td>textarea {
    margin: 0 !important;
}

.schema-edit-template {
    .content-container {
        display: flex;
        flex-direction: row;
        .buttons {
            width: 20em;
            flex-grow: 0;
            display: flex;
            flex-direction: column;
            padding: 0 1rem;
        }
        .form {
            flex-grow: 1;
            padding-right: 5rem;
            .accordion-item {
                background-color: $formBG;
                a.accordion-title {
                    color: black;
                    font-size: initial;
                    padding: 0.5rem 1rem;
                }
            }
        }
    }
}

.dateTimePicker {
    input,
    select {
        width: auto;
        display: inline-block;
    }
}

.notification-list {
    position: fixed;
    right: 20px;
    max-width: 30%;
    z-index: 10;
    .notification {
        .notification-container {
            //border: 2px solid black;
            border-radius: .5rem;
            position: relative;
            padding: 20px;
            margin: 20px;
            &.error {
                background-color: red;
                color: white;
                .closer {
                    border-color: white;
                }
            }
            &.warning {
                background-color: orange;
            }
            &.ok {
                background-color: green;
            }
            .headline {
                font-weight: bold;
            }
            .closer {
                position: absolute;
                top: 4px;
                right: 4px;
                border: 1px solid black;
                padding: 0 2px;
            }
            .messageentry {
                margin-left: 10px;
                margin-top: 9px;
            }
            .messagedetails {
                border: 1px solid grey;
                border-radius: .5rem;
                text-align: left;
                font-size: 10px;
                margin-top: 8px;
                padding-bottom: 8px;
            }
        }
    }
}

.data-grid-columns {
    .accordion-item .accordion-title {
        color: $primary-font-color;
        font-size: 1rem;
    }
}

.infoi {
    display: inline-block;
    text-align: center;
    width: 20px;
    height: 20px;
    line-height: 20px;
    color: $primary-font-color;
    border: 1px solid $primary-font-color;
    border-radius: 100%;
}

.circle {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    &.green {
        background-color: #008000;
    }
    &.red {
        background-color: #ff0000;
    }
}

.schema-edit-template {
    .table-create {
        button {
            margin: 0
        }
    }
}

.schemaQueryForm {
    .headline {
        background-color: $formBG;
        color: black;
        margin-bottom: 10px;
        label {
            text-align: left;
        }
    }
    .inputDropdown {
        position: relative;
        div.results {
            position: absolute;
            z-index: 10;
            background-color: white;
            border: 1px solid #636363;
            box-shadow: 0px 0px 5px #7d7d7d;
            transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
            border-top: 0;
            margin-top: -1rem;
            width: 100%;
            ul {
                max-height: 20vH;
                overflow: auto;
                li {
                    text-align: left;
                    cursor: pointer;
                    padding: 0;
                    &:hover {
                        background-color: $formBG;
                    }
                    &.selected {
                        background-color: $formBG;
                        border: 1px solid #aaa;
                    }
                    padding-right: 10px;
                    a {
                        padding: 0;
                    }
                }
            }
        }
    }
    .aclTargetContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .aclTarget {
            display: flex;
            flex-direction: row;
            margin-right: 10px;
            flex-basis: 18%;
            i,
            span {
                display: flex;
                align-items: center;
            }
            span {
                color: black;
                padding-left: 5px;
                padding-right: 10px;
                flex-grow: 1
            }
            i.delete {
                color: darkred;
            }
        }
    }
}

// Defaulttheme
.topnavbar {
    display: none;
}

body {
    background-color: white;
}

.text-right {
    text-align: right;
    justify-content: flex-end;
}

.defaulttheme {
    .hidein_default {
        display: none !important;
    }
    .top-bar input {
        margin-left: 20px;
    }
    .listitemrow {
        color: #3F3F3F;
        padding: 4px 10px 7px 10px;
        display: flex;
        .listItemTitle {
            flex: 1 1 auto;
            text-align: left;
            font-weight: 600;
            font-size: 15px;
            color: #3F3F3F;
            line-height: 18px;
            padding-top: 4px;
            margin-left: 5px;
            cursor: pointer;
        }
        .listItemTitle:hover {
            text-decoration: underline;
        }
        .removeButton {
            flex: 0 1;
            background-size: 12px 15px;
            background-repeat: no-repeat;
            background-position: 50% 0%;
            background-image: url(img/icon_trash.png);
            min-width: 20px;
            margin-top: 3px;
            cursor: pointer;
        }
    }
    .backend-template ul.processtree li.selected {
        /* border: 1px solid #ccc; */
        border-radius: 4px;
        /* padding: 0.3rem; */
        margin-left: -0.3rem;
        background-color: #fdfdfd;
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
    }
    .toolbar {
        .spaltelinks,
        .spalterechts {
            display: flex;
            flex: 1 1 auto;
        }
        .spaltelinks {
            flex-direction: column;
            .subtitel {
                font-weight: 200;
                font-size: 26px;
                color: #696969;
            }
            .processtitel {
                font-weight: 600;
                font-size: 34px;
                color: #3c3c3c;
            }
        }
        .spalterechts {
            justify-content: flex-end;
            align-items: flex-end;
            button {
                margin: 0px 0px 0px 15px;
            }
        }
    }
    .toolbar>.row {
        width: 100%;
    }
    .fixedToolbar {
        background-color: grey;
        border: none;
        box-shadow: 0px 3px 14px #66666678;
        .spaltelinks {
            .processtitel {
                color: white;
                font-weight: 600;
                font-size: 24px;
                line-height: 34px;
            }
        }
        .spalterechts {
            justify-content: flex-end;
            align-items: center;
        }
    }
    .fixedToolbar .spaltelinks .subtitel {
        display: none;
    }
    .savebutton {
        color: rgb(0, 0, 0) !important;
        text-decoration: none !important;
        opacity: 1;
        transition: all 0.5sec ease-out;
    }
    .savebutton:disabled {
        color: #292929 !important;
        opacity: .5;
        transition: all 0.5sec ease-out;
    }
    .newWidget {
        cursor: pointer;
    }
    .newWidget:hover {
        text-decoration: underline;
    }
    .backend-template ul.processtree ul {
        margin: 0px 0px 0px 0.5rem;
    }
    .backend-template .row90,
    .row {
        max-width: 1200px !important;
        min-width: auto !important;
    }
    .li:selected {
        padding: 0rem;
    }
    .listitemrow.selected {
        background-color: grey;
        color: white;
        .listItemTitle {
            color: white;
        }
        .removeButton:hover {
            flex: 0 1;
            background-size: 12px 15px;
            background-repeat: no-repeat;
            background-position: 50% 0%;
            background-image: url(img/icon_trash_weiss.png);
            min-width: 20px;
            margin-top: 3px;
        }
    }
    .invisibledialog {
        display: none;
    }
    .buttoncollection_nt {
        display: none;
    }
    .buttoncollection {
        display: inline-block;
    }
    .backend-template {
        .tree {
            &.select {
                box-shadow: 0 0 5px #FF8000;
            }
            ul.processtree {
                .listitemrow {
                    &.target-selected {
                        box-shadow: 0 0 5px #FF8000;
                        background-color: #FF8000;
                    }
                }
                li.selected {
                    border: 0px solid #ccc;
                    border-radius: 4px;
                    padding: 0rem;
                    margin-left: -0.3rem;
                    background-color: #fdfdfd;
                    margin-top: 0.3rem;
                    margin-bottom: 0.3rem;
                }
            }
        }
    }
}

//  _   _ _______    _____ _    _ _____  ______ _____    _______ _    _ ______ __  __ ______  
// | \ | |__   __|  / ____| |  | |  __ \|  ____|  __ \  |__   __| |  | |  ____|  \/  |  ____| 
// |  \| |  | |    | (___ | |  | | |__) | |__  | |__) |    | |  | |__| | |__  | \  / | |__    
// | . ` |  | |     \___ \| |  | |  ___/|  __| |  _  /     | |  |  __  |  __| | |\/| |  __|   
// | |\  |  | |     ____) | |__| | |    | |____| | \ \     | |  | |  | | |____| |  | | |____  
// |_| \_|  |_|    |_____/ \____/|_|    |______|_|  \_\    |_|  |_|  |_|______|_|  |_|______| 
//
// 
.ntsupertheme {
    // skdjfhlskjfhldskjfhlkdsjh
    .hidein_ntst {
        display: none !important;
    }
    .marginBottom10 {
        margin-bottom: 10px;
    }
    .textalignRight {
        text-align: right;
    }
    .textalignLeft {
        text-align: left;
    }
    font-family: 'Titillium Web';
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Titillium Web';
    }
    H2 {
        font-weight: 600;
        font-size: 34px;
        color: $highlightColor;
        text-transform: uppercase;
    }
    .top-bar {
        display: none;
    }
    .capitalize {
        text-transform: capitalize;
    }
    H1,
    H2,
    H3,
    H4,
    H5 {
        color: $highlightColor;
    }
    H1 {
        font-weight: 300;
        font-size: 40px;
        color: $highlightColor;
    }
    H2 {
        font-weight: 600;
        font-size: 34px;
        color: $highlightColor;
        text-transform: uppercase;
    }
    .topnavbar {
        display: flex;
        width: 100%;
        height: 118px;
        transition: height 0.5s ease-out;
        border-bottom: 1px solid #EEEEEE;
        align-items: center;
        justify-content: center;
    }
    .topnavbar.slim {
        height: 56px;
        transition: height 0.5s ease-out;
    }
    .topnavbar .headlogo {
        min-width: 265px;
        max-width: 265px;
        min-height: 37px;
        max-height: 37px;
        background-image: url('img/headlogo.png');
        background-repeat: no-repeat;
        background-size: 265px 37px;
        background-position: 0% 50%;
    }
    .topnavbar .innerwrapper {
        max-width: 90%;
        display: flex;
        align-items: center;
        width: 100%;
    }
    .topnavbar .spaltelinks {
        display: flex;
        flex: 1 1 auto;
        justify-content: flex-start;
        align-items: center;
    }
    .topnavbar .spalterechts {
        display: flex;
        flex: 1 1 auto;
        text-align: right;
        justify-content: flex-end;
        align-items: center;
    }
    .topnavbar .spalterechts .item {
        font-size: 16px;
        color: #949494;
        margin-left: 30px;
    }
    .topnavbar a {
        font-size: 16px;
        color: #949494;
    }
    .topnavbar a {
        color: $highlightColor;
    }
    .topnavbar .spalterechts .item:hover {
        color: $highlightColor;
        text-decoration: underline;
    }
    .topnavbar .useritem {
        height: 28px;
        line-height: 26px;
        border: 1px solid #D3D3D3;
        border-radius: 14px;
        padding: 0px 29px 4px 15px;
        cursor: pointer;
        position: relative;
    }
    .topnavbar .useritem .dreieck {
        position: absolute;
        width: 10px;
        height: 6px;
        background-size: 10px 6px;
        left: 92px;
        top: 11px;
        background-repeat: no-repeat;
    }
    .topnavbar .useritem:hover {
        color: white !important;
        text-decoration: none !important;
        background-image: linear-gradient(219deg, #00C7FF 0%, #0095FF 100%);
    }
    .plusbutton {
        position: relative;
    }
    .invisibledialog {
        display: none;
        position: absolute;
        background: #FFFFFF;
        box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.38);
        border-radius: 19px;
        flex-direction: column;
        overflow: hidden;
        min-width: 200px;
        right: -35px;
        top: 20px;
        z-index: 999;
    }
    .invisibledialog .dialogitem {
        width: 100%;
        border-top: 1px solid #DFDFDF;
        text-align: left;
        font-size: 16px;
        color: #B6B6B6;
        padding: 8px 20px 8px 20px;
        line-height: 24px;
        cursor: pointer;
    }
    .invisibledialog .dialogitem a {
        font-size: 16px;
        color: #B6B6B6 !important;
    }
    .invisibledialog .dialogitem:hover a,
    .invisibledialog .dialogitem a:hover {
        color: white !important;
    }
    .invisibledialog .dialogitem:hover {
        background-image: linear-gradient(219deg, #00C7FF 0%, #0095FF 100%);
        color: white !important;
    }
    .invisibledialog .dialogitem:first-child {
        border-top: none;
    }
    .topnavbar .spalterechts .item:hover .invisibledialog {
        display: flex;
    }
    .topnavbar .useritem .dreieck {
        background-image: url('img/dreieck.png');
    }
    .topnavbar .useritem:hover .dreieck {
        background-image: url('img/dreieck_weiss.png');
    }
    .roundbuttons button {
        height: 28px;
        line-height: 26px;
        border: 1px solid #D3D3D3;
        border-radius: 14px !important;
        padding: 0px 15px 4px 15px;
        cursor: pointer;
        position: relative;
        background: white !important;
        color: #B6B6B6 !important;
        margin-left: 12px;
    }
    .roundbuttons button:hover {
        color: white !important;
        text-decoration: none !important;
        border: 1px solid #fff;
        background-image: linear-gradient(219deg, #00C7FF 0%, #0095FF 100%) !important;
    }
    .roundbuttons button a {
        color: #B6B6B6 !important;
        text-decoration: none !important;
    }
    .roundbuttons button:hover a {
        color: white !important;
        text-decoration: none !important;
    }
    *:focus {
        outline: none;
    }
    .titlebar {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        .spalte {
            display: flex;
            flex: 1 1 auto;
        }
        .spalte.rechts {
            justify-content: flex-end;
        }
    }
    table.prozesse {
        margin-bottom: 200px;
    }
    .prozesse {
        thead,
        tbody,
        tfoot {
            border: none;
        }
        thead th,
        thead td,
        tfoot th,
        tfoot td,
        tbody th,
        tbody td {
            font-weight: 300;
            padding: 0px;
        }
        thead {
            background: transparent;
            color: #9C9C9C;
            font-weight: 300;
        }
        tbody {
            font-size: 16px;
            color: #7d7d7d;
            line-height: 40px;
            .pname a {
                font-weight: 600;
                color: #3A3A3A;
            }
            .smaller {
                font-size: 14px;
                color: #7d7d7d;
            }
            .grey,
            .grey a {
                color: #7d7d7d;
            }
        } //hfghg h gdh dgh
        tbody tr {
            border-top: 1px solid #E7E7E7;
        }
        tbody tr.first {
            border-top: 5px solid white;
        }
        .idfeld {
            padding-left: 5px;
            max-width: 20px;
            text-align: right;
            padding-right: 16px;
        }
        tbody tr:hover {
            background-image: linear-gradient(219deg, #00C7FF 0%, #0095FF 100%);
            color: white !important;
            .pname a,
            .smaller,
            .grey,
            .grey a {
                color: white !important;
            }
            .editbutton:hover {
                background-image: url('img/icon_edit_weiss.png');
                opacity: 1;
            }
            .editbuttonsimple:hover {
                background-image: url('img/icon_edit_simple_weiss.png');
                opacity: 1;
            }
            .trashbutton:hover {
                background-image: url('img/icon_trash_weiss.png');
                opacity: 1;
            }
            .plusbutton:hover {
                background-image: url('img/icon_plus_weiss.png');
                opacity: 1;
            }
            .editbutton {
                background-image: url('img/icon_edit_weiss.png');
                opacity: 0.5;
            }
            .editbuttonsimple {
                background-image: url('img/icon_edit_simple_weiss.png');
                opacity: 0.5;
            }
            .trashbutton {
                background-image: url('img/icon_trash_weiss.png');
                opacity: 0.5;
            }
            .plusbutton {
                background-image: url('img/icon_plus_weiss.png');
                opacity: 0.5;
            }
        }
    }
    .buttoncollection_nt {
        display: inline-block;
    }
    .buttoncollection {
        display: none;
    }
    .buttonleisteprozesse {
        .editbutton,
        .editbuttonsimple,
        .plusbutton,
        .trashbutton {
            margin-top: 10px;
            margin-right: 17px;
        }
        .plusbutton {
            position: relative;
        }
        .plusbutton:hover .invisibledialog {
            display: flex;
            right: -35px;
        }
    }
    .editbutton {
        width: 19px;
        height: 22px;
        background-image: url('img/icon_edit.png');
        background-size: 19px 22px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        cursor: pointer;
        display: inline-block;
    }
    .editbutton:hover {
        background-image: url('img/icon_edit_hover.png');
    }
    .editbuttonsimple {
        width: 22px;
        height: 22px;
        background-image: url('img/icon_edit_simple.png');
        background-size: 22px 22px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        cursor: pointer;
        display: inline-block;
    }
    .editbuttonsimple:hover {
        background-image: url('img/icon_edit_simple_hover.png');
    }
    .trashbutton {
        width: 19px;
        height: 22px;
        background-image: url('img/icon_trash.png');
        background-size: 19px 22px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        cursor: pointer;
        display: inline-block;
    }
    .trashbutton:hover {
        background-image: url('img/icon_trash_hover.png');
    }
    .plusbutton {
        width: 22px;
        height: 21px;
        background-image: url('img/icon_plus.png');
        background-size: 22px 21px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        cursor: pointer;
        display: inline-block;
    }
    .plusbutton:hover {
        background-image: url('img/icon_plus_hover.png');
    }
    .text-right {
        text-align: right;
        justify-content: flex-end;
    }
    .edit-process-template {
        .toolbar {
            .spaltelinks,
            .spalterechts {
                display: flex;
                flex: 1 1 auto;
            }
            .spaltelinks {
                flex-direction: column;
                .subtitel {
                    font-weight: 200;
                    font-size: 26px;
                    color: $highlightColor;
                }
                .processtitel {
                    font-weight: 600;
                    font-size: 34px;
                    color: $highlightColor;
                }
            }
            .spalterechts {
                justify-content: flex-end;
                align-items: flex-end;
            }
        }
        .fixedToolbar {
            background-image: linear-gradient(219deg, #00C7FF 0%, #0095FF 100%) !important;
            border: none;
            box-shadow: 0px 3px 14px #66666678;
            .spaltelinks {
                .processtitel {
                    color: white;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 34px;
                }
            }
            .spalterechts {
                justify-content: flex-end;
                align-items: center;
            }
        }
        .fixedToolbar .spaltelinks .subtitel {
            display: none;
        }
        .savebutton {
            color: white !important;
            text-decoration: none !important;
            border: 1px solid #fff;
            background-image: linear-gradient(219deg, #00C7FF 0%, #0095FF 100%) !important;
            opacity: 1;
            transition: all 0.5sec ease-out;
        }
        .savebutton:disabled {
            border: 1px solid #D3D3D3;
            background: white !important;
            color: #B6B6B6 !important;
            opacity: .5;
            transition: all 0.5sec ease-out;
        }
        .process-content {
            border: none;
            @include simpleTopBorder();
        }
        .backend-template .processtree {
            margin-left: 0px;
        }
        .tabs-title.is-active {
            background-image: linear-gradient( 219deg, #00C7FF 0%, #0095FF 100%);
            border-radius: 14.25px;
            height: 28px;
            line-height: 28px;
        }
        .tabs-title>a:focus,
        .tabs-title.is-active a,
        .tabs-title>a[aria-selected='true'] {
            background: transparent !important;
            color: white !important;
        }
        .tabs-title a {
            line-height: 28px;
            padding: 0px 12px 0px 12px !important;
            font-weight: 400;
            font-size: 16px;
            color: #B1B1B1;
            text-transform: uppercase;
        }
        .tabs {
            margin-top: 20px;
            margin-bottom: 10px;
            border-bottom: 1px solid $simpleLine;
            padding-bottom: 20px;
        }
    }
    .backend-template {
        .tree {
            &.select {
                box-shadow: 0 0 5px #FF8000;
                ul.processtree {
                    .listitemrow {
                        &.target-selected {
                            box-shadow: 0 0 5px #FF8000;
                            background-color: #FF8000;
                        }
                    }
                }
            }
        }
        .expander {
            background-color: transparent !important;
            color: transparent !important;
            background-size: 20px 19px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            max-width: 20px;
            flex: 0 1;
            min-width: 20px;
            min-height: 19px;
            cursor: pointer;
        }
        .expander.plus {
            background-image: url('img/icon_lrg_plus_voll_grau.png');
        }
        .expander.minus {
            background-image: url('img/icon_lrg_minus_voll_grau.png');
        }
        .processtree {
            min-width: 250px;
            margin-left: 0px;
            max-width: 350px;
        }
        .processtreeContainer {
            overflow: auto;
            max-height: 600px;
            border: 1px solid $simpleLine;
            border-right: none;
            padding: 0px;
            box-shadow: none;
            margin: 0;
        }
        ul.processtree li:last-child {
            border-bottom: none !important;
        }
        ul.processtree li {
            border: none;
            border-radius: 0px;
            padding: 0rem;
            margin-left: 0rem;
            background-color: transparent;
            margin-top: 0rem;
            margin-bottom: 0rem;
            border-bottom: 1px solid #e7e7e7;
            .listitemrow {
                color: #3F3F3F;
                padding: 4px 10px 7px 10px;
                display: flex;
                .listItemTitle {
                    flex: 1 1 auto;
                    text-align: left;
                    font-weight: 600;
                    font-size: 15px;
                    color: #3F3F3F;
                    line-height: 18px;
                    padding-top: 4px;
                    margin-left: 5px;
                    cursor: pointer;
                }
                .listItemTitle:hover {
                    text-decoration: underline;
                }
                .removeButton {
                    flex: 0 1;
                    background-size: 12px 15px;
                    background-repeat: no-repeat;
                    background-position: 50% 0%;
                    background-image: url(img/icon_trash.png);
                    min-width: 20px;
                    margin-top: 3px;
                    cursor: pointer;
                }
                .addButton {
                    border: none !important;
                    background-size: 15px 15px;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    background-image: url(img/icon_lrg_plus_leer_grau.png);
                    min-width: 20px;
                    color: transparent;
                    background-color: transparent !important;
                }
                .addButton:hover {
                    background-image: url(img/icon_lrg_plus_leer_weiss.png);
                }
                .deleteButton {
                    border: none !important;
                    background-size: 12px 15px;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    background-image: url(img/icon_trash.png);
                    min-width: 20px;
                    color: transparent;
                    background-color: transparent !important;
                }
                .deleteButton:hover {
                    background-image: url(img/icon_trash_weiss.png);
                }
            }
            .listitemrow:hover {
                background-image: linear-gradient(225deg, #00C7FF 0%, #0095FF 100%);
                color: white;
                .listItemTitle {
                    color: white;
                }
                .removeButton:hover {
                    background-image: url(img/icon_trash_weiss.png);
                    opacity: 1;
                }
                .removeButton {
                    flex: 0 1;
                    background-size: 12px 15px;
                    background-repeat: no-repeat;
                    background-position: 50% 0%;
                    background-image: url(img/icon_trash_weiss.png);
                    min-width: 20px;
                    margin-top: 5px;
                    cursor: pointer;
                    opacity: 0.5;
                }
                .addButton {
                    background-image: url(img/icon_lrg_plus_leer_weiss.png);
                    background-color: transparent !important;
                }
                .deleteButton {
                    background-image: url(img/icon_trash_weiss.png);
                    background-color: transparent !important;
                    opacity: 0.5;
                }
                .deleteButton:hover {
                    background-image: url(img/icon_trash_weiss.png);
                    opacity: 1;
                }
            }
            .listitemrow.selected {
                background-image: linear-gradient(225deg, #00C7FF 0%, #0095FF 100%);
                color: white;
                .listItemTitle {
                    color: white;
                }
                .removeButton:hover {
                    flex: 0 1;
                    background-size: 12px 15px;
                    background-repeat: no-repeat;
                    background-position: 50% 0%;
                    background-image: url(img/icon_trash_weiss.png);
                    min-width: 20px;
                    margin-top: 3px;
                }
            }
        }
        ul.processtree li.selected {
            border: none;
            border-radius: 0px;
            padding: 0rem;
            margin-left: 0rem;
            background-color: transparent;
            margin-top: 0rem;
            margin-bottom: 0rem;
        }
        ul.processtree .lidefaultpadding {
            padding-bottom: 5px;
            padding-top: 3px;
        }
        ul.processtree ul {
            margin: 0px 0px 0px 10px;
        }
        ul.processtree ul.firstlayer {
            margin: 0px 0px 0px 0px;
        }
        .prozessspezliste button.hollow.button {
            border: none !important;
            font-size: 14px !important;
            padding-bottom: 9px;
            line-height: 16px;
            margin-top: 6px;
        }
        .vorlagentitel {
            font-size: 16px;
            font-weight: 600;
            line-height: 50px;
        }
        .newWidget {
            border: 1px solid $lightGray;
            border-radius: 14.25px;
            height: 23px;
            cursor: pointer;
            display: flex;
            margin: 6px 0px 6px 20px;
            max-width: 110px;
            .icon {
                width: 15px;
                height: 20px;
                margin: 0px 5px 0px 3px;
                background-size: 15px 15px;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-image: url(img/icon_lrg_plus_leer_grau.png);
            }
            .titel {
                font-weight: 300;
                font-size: 13px;
                line-height: 22px;
                color: #868686;
            }
        }
        .newWidget:hover {
            background-image: linear-gradient(225deg, #00C7FF 0%, #0095FF 100%);
            .icon {
                background-image: url(img/icon_lrg_plus_leer_weiss.png);
            }
            .titel {
                color: white;
            }
        }
        .customroundbutton {
            cursor: pointer;
            border: 1px solid $lightGray;
            border-radius: 14.25px;
            height: 28px;
            display: inline-block;
            padding: 0px 10px 0px 0px;
            font-size: 14px;
            color: #868686;
            background: transparent;
            line-height: 26px;
            .addButton {
                border: none !important;
                background-size: 20px 20px;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-image: url(img/icon_lrg_plus_leer_grau.png);
                min-width: 20px;
                color: transparent;
                background-color: transparent !important;
                height: 100%;
                display: inline-block;
                float: left;
                margin-left: 3px;
                margin-right: 3px;
            }
            .text {
                font-size: 14px;
                color: #868686;
                display: inline-block;
            }
        }
        .customroundbutton.ohneIcon {
            padding-left: 10px;
        }
        .customroundbutton:hover {
            background-image: linear-gradient(225deg, #00C7FF 0%, #0095FF 100%);
            color: white;
            border: 1px solid white;
        }
        .customroundbutton:hover .text {
            color: white;
        }
        .customroundbutton:hover .addButton {
            border: none !important;
            background-size: 15px 15px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-image: url(img/icon_lrg_plus_leer_weiss.png);
            min-width: 20px;
            color: transparent;
            background-color: transparent !important;
        }
    }
    .deleteButton {
        border: none !important;
        background-size: 17px 19px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-image: url(img/icon_trash.png);
        min-width: 19px;
        max-width: 19px;
        min-height: 21px;
        color: transparent;
        background-color: transparent !important;
        cursor: pointer;
    }
    .deleteButton:hover {
        background-image: url(img/icon_trash_hover.png);
    }
    .keyvaluepairrow {
        input {
            height: 50px;
        }
        select {
            margin: 0px;
            height: 50px;
        }
        textarea {
            width: 100% !important;
        }
        .functionsColumn {
            max-width: 25px;
        }
    }
    .rowtitel {
        font-size: 16px;
        font-weight: 600;
        color: #3f3f3f;
    }
    .cleanStyleTable {
        thead,
        tbody,
        tfoot,
        tbody tr:nth-child(even) {
            background: transparent;
            border: none;
        }
        th {
            color: $defaultPrimaryColor;
            font-weight: 600;
        }
        td.actionButtons button {
            margin-right: 10px;
        }
    }
    .cleanStyleTable.rowBorders tr {
        border-bottom: 1px solid $simpleLine;
    }
}
