/* no special fonts */

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(fonts/material/MaterialIcons-Regular.eot);
    /* For IE6-8 huh? */
    src: local('Material Icons'), local('MaterialIcons-Regular'), url(fonts/material/MaterialIcons-Regular.woff2) format('woff2'), url(fonts/material/MaterialIcons-Regular.woff) format('woff'), url(fonts/material/MaterialIcons-Regular.ttf) format('truetype');
}


/* Rules for sizing the icon. */

.material-icons.md-18 {
    font-size: 18px;
}

.material-icons.md-24 {
    font-size: 24px;
}

.material-icons.md-36 {
    font-size: 36px;
}

.material-icons.md-48 {
    font-size: 48px;
}


/* Rules for using icons as black on a light background. */

.material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
}

.material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
}


/* Rules for using icons as white on a dark background. */

.material-icons.md-light {
    color: rgba(255, 255, 255, 1);
}

.material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
}


/* titillium-web-200 - latin */

@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 200;
    src: url('fonts/titillium/titillium-web-v9-latin-200.eot');
    /* IE9 Compat Modes */
    src: local(''), url('fonts/titillium/titillium-web-v9-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/titillium/titillium-web-v9-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/titillium/titillium-web-v9-latin-200.woff') format('woff'), /* Modern Browsers */
    url('fonts/titillium/titillium-web-v9-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/titillium/titillium-web-v9-latin-200.svg#TitilliumWeb') format('svg');
    /* Legacy iOS */
}


/* titillium-web-300 - latin */

@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 300;
    src: url('fonts/titillium/titillium-web-v9-latin-300.eot');
    /* IE9 Compat Modes */
    src: local(''), url('fonts/titillium/titillium-web-v9-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/titillium/titillium-web-v9-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/titillium/titillium-web-v9-latin-300.woff') format('woff'), /* Modern Browsers */
    url('fonts/titillium/titillium-web-v9-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/titillium/titillium-web-v9-latin-300.svg#TitilliumWeb') format('svg');
    /* Legacy iOS */
}


/* titillium-web-regular - latin */

@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    src: url('fonts/titillium/titillium-web-v9-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local(''), url('fonts/titillium/titillium-web-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/titillium/titillium-web-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/titillium/titillium-web-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('fonts/titillium/titillium-web-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/titillium/titillium-web-v9-latin-regular.svg#TitilliumWeb') format('svg');
    /* Legacy iOS */
}


/* titillium-web-600 - latin */

@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    src: url('fonts/titillium/titillium-web-v9-latin-600.eot');
    /* IE9 Compat Modes */
    src: local(''), url('fonts/titillium/titillium-web-v9-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/titillium/titillium-web-v9-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/titillium/titillium-web-v9-latin-600.woff') format('woff'), /* Modern Browsers */
    url('fonts/titillium/titillium-web-v9-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/titillium/titillium-web-v9-latin-600.svg#TitilliumWeb') format('svg');
    /* Legacy iOS */
}


/* titillium-web-700 - latin */

@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    src: url('fonts/titillium/titillium-web-v9-latin-700.eot');
    /* IE9 Compat Modes */
    src: local(''), url('fonts/titillium/titillium-web-v9-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/titillium/titillium-web-v9-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/titillium/titillium-web-v9-latin-700.woff') format('woff'), /* Modern Browsers */
    url('fonts/titillium/titillium-web-v9-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/titillium/titillium-web-v9-latin-700.svg#TitilliumWeb') format('svg');
    /* Legacy iOS */
}


/* titillium-web-900 - latin */

@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 900;
    src: url('fonts/titillium/titillium-web-v9-latin-900.eot');
    /* IE9 Compat Modes */
    src: local(''), url('fonts/titillium/titillium-web-v9-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/titillium/titillium-web-v9-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/titillium/titillium-web-v9-latin-900.woff') format('woff'), /* Modern Browsers */
    url('fonts/titillium/titillium-web-v9-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/titillium/titillium-web-v9-latin-900.svg#TitilliumWeb') format('svg');
    /* Legacy iOS */
}