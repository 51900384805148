.nomargin {
    margin: 0px;
}

.sticky-toolbar {
    position: absolute;
    top: 55px;
    left: 0;
    width: 100%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    right: 0;
}

.process-content {
    margin-top: 140px;
}

.fixedToolbar {
    position: fixed;
    top: 0;
    background-color: #f3f3f3;
    z-index: 2000;
    border: 1px solid #ccc;
    padding: 0.2rem 1rem;
    box-shadow: 3px 3px 6px #666666;
    right: 0;
    margin-top: 0 !important;
    max-width: auto;
    width: 100%;
}

.fixedToolbar {
    h1,
    h2 {
        font-size: 1.5rem;
    }
}

.CodeMirror {
    border: 1px solid #ccc;
}

.expanded .CodeMirror {
    height: 600px;
    position: absolute;
    margin-left: -20%;
    box-shadow: 3px 3px 6px #666666;
}

.custom-code-edit {
    p.code {
        white-space: pre-wrap;
        font-family: monospace;
    }
    p.code-preview {
        white-space: pre-wrap;
        font-family: monospace;
    }
}

.flexbox {
    display: flex;
}

button.addwidgeticons {
    border: none !important;
    background: transparent !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    color: #4F4F4F !important;
    font-size: 14px;
    margin-right: 15px;
    opacity: 0.7;
    width: 100px;
}

button.addwidgeticons img {
    margin-bottom: 10px;
}

button.addwidgeticons:hover {
    text-decoration: underline;
    opacity: 1;
}


/* Globale Helferklassen */

.hidden, .hide {
    display:none !important;
  }
  
  .invisible {
    visibility: hidden !important;
  }
  
  .linkhand, .pointer{
    cursor: pointer;
  }
  
  .centered{
    text-align: center;
  }
  .textalignleft{
    text-align: left !important;
  }
  .textalignright{
    text-align: right !important;
  }
  
  .alignselfcenter{
    align-self: center;
  }
  
  .floatleft{
    float: left;
  }
  .floatright{
    float: right;
  }
  .stretch{
    flex: 1 1 auto;
  }
  .imgmaxwidthnone{
     max-width: none;
   }
  
  // Flexbox Helper
  
  .flexbox{
    display: flex;
  }
  .flexdir_row{
    flex-direction: row;
  }
  .flexdir_column{
    flex-direction: column;
  }
  .justifycenter{
    justify-content: center;
  }
  .justifyspacebetween{
    justify-content: space-between;
  }
  .justifyend{
    justify-content: flex-end !important;
  }
  .aligncontentcenter{
    align-content: center;
  }
  
  .alignitemscenter{
    align-items: center;
  }
  
  .containerBox-center {
    display:flex;
    justify-content: center;
    align-items: center;
  
  }
  
  .containerBox-between {
    display: flex;
    justify-content: space-between;
  }
  
  .container-box,
  .containerBox-around {
    display: flex;
    justify-content: space-around;
  }
  
  .boldfont{
    font-weight: bold;
  }
  
  .spacer10{
    width: 100%;
    height: 10px;
  }
  .spacer20{
    width: 100%;
    height: 20px;
  }
  .spacer25{
    width: 100%;
    height: 25px;
  }
  .spacer30{
    width: 100%;
    height: 30px;
  }
  .spacer40{
    width: 100%;
    height: 40px;
  }
  .spacer50{
    width: 100%;
    height: 50px;
  }
  .spacer100{
    width: 100%;
    height: 100px;
  }
  
  
  // margin Klassen
  .margintop1{
    margin-top: 1px;
  }
  .margintop2{
    margin-top: 2px;
  }
  .margintop3{
    margin-top: 3px;
  }
  .margintop4{
    margin-top: 4px;
  }
  .margintop5{
    margin-top: 5px;
  }
  .margintop6{
    margin-top: 6px;
  }
  .margintop7{
    margin-top: 7px;
  }
  .margintop8{
    margin-top: 8px;
  }
  .margintop9{
    margin-top: 9px;
  }
  .margintop10{
    margin-top: 10px;
  }
  
  .margintop15{
    margin-top: 15px;
  }
  .margintop20{
    margin-top: 20px;
  }
  .margintop25{
    margin-top: 25px;
  }
  .margintop30{
    margin-top: 30px;
  }
  .margintop35{
    margin-top: 35px;
  }
  .margintop40{
    margin-top: 40px;
  }
  .margintop45{
    margin-top: 45px;
  }
  .margintop50{
    margin-top: 50px;
  }
  
  
  .marginright1{
    margin-right: 1px;
  }
  .marginright2{
    margin-right: 2px;
  }
  .marginright3{
    margin-right: 3px;
  }
  .marginright4{
    margin-right: 4px;
  }
  .marginright5{
    margin-right: 5px;
  }
  .marginright6{
    margin-right: 6px;
  }
  .marginright7{
    margin-right: 7px;
  }
  .marginright8{
    margin-right: 8px;
  }
  .marginright9{
    margin-right: 9px;
  }
  .marginright10{
    margin-right: 10px;
  }
  
  .marginright15{
    margin-right: 15px;
  }
  .marginright20{
    margin-right: 20px;
  }
  .marginright25{
    margin-right: 25px;
  }
  .marginright30{
    margin-right: 30px;
  }
  .marginright35{
    margin-right: 35px;
  }
  .marginright40{
    margin-right: 40px;
  }
  .marginright45{
    margin-right: 45px;
  }
  .marginright50{
    margin-right: 50px;
  }
  
  .marginbottom1{
    margin-bottom: 1px;
  }
  .marginbottom2{
    margin-bottom: 2px;
  }
  .marginbottom3{
    margin-bottom: 3px;
  }
  .marginbottom4{
    margin-bottom: 4px;
  }
  .marginbottom5{
    margin-bottom: 5px;
  }
  .marginbottom6{
    margin-bottom: 6px;
  }
  .marginbottom7{
    margin-bottom: 7px;
  }
  .marginbottom8{
    margin-bottom: 8px;
  }
  .marginbottom9{
    margin-bottom: 9px;
  }
  .marginbottom10{
    margin-bottom: 10px;
  }
  
  .marginbottom15{
    margin-bottom: 15px;
  }
  .marginbottom20{
    margin-bottom: 20px;
  }
  .marginbottom25{
    margin-bottom: 25px;
  }
  .marginbottom30{
    margin-bottom: 30px;
  }
  .marginbottom35{
    margin-bottom: 35px;
  }
  .marginbottom40{
    margin-bottom: 40px;
  }
  .marginbottom45{
    margin-bottom: 45px;
  }
  .marginbottom50{
    margin-bottom: 50px;
  }
  
  .marginleft1{
    margin-left: 1px;
  }
  .marginleft2{
    margin-left: 2px;
  }
  .marginleft3{
    margin-left: 3px;
  }
  .marginleft4{
    margin-left: 4px;
  }
  .marginleft5{
    margin-left: 5px;
  }
  .marginleft6{
    margin-left: 6px;
  }
  .marginleft7{
    margin-left: 7px;
  }
  .marginleft8{
    margin-left: 8px;
  }
  .marginleft9{
    margin-left: 9px;
  }
  .marginleft10{
    margin-left: 10px;
  }
  
  .marginleft15{
    margin-left: 15px;
  }
  .marginleft20{
    margin-left: 20px;
  }
  .marginleft25{
    margin-left: 25px;
  }
  .marginleft30{
    margin-left: 30px;
  }
  .marginleft35{
    margin-left: 35px;
  }
  .marginleft40{
    margin-left: 40px;
  }
  .marginleft45{
    margin-left: 45px;
  }
  .marginleft50{
    margin-left: 50px;
  }
  
  .boldfont{
    font-weight: bold;
  }
  
  .nopadding{
    padding: 0px;
  }
  .nomargin{
    margin: 0px;
  }
  .noborder {
    border: 0 !important;
    box-shadow: none !important;
  }
  
  /* Helferklassen Ende */